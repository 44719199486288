html,
body,
#app {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #111012;
  }
}

input {
  width: 100%;
  box-sizing: border-box;
}

@keyframes bounce {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

* {
  outline: none;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: auto;
}

/* @workaround for bg on select dropdown on windows or firefox */
@media (prefers-color-scheme: dark) {
  option {
    background-color: #333436;
  }
}

.stabilize {
  transform: translateZ(0);
}

a {
  text-decoration: none;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
}

#navbar {
  -webkit-user-select: none;
  -webkit-app-region: drag;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/* Hides google map metadata */
a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url('https://s.catch.co/fonts/inter/Inter-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('https://s.catch.co/fonts/inter/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url('https://s.catch.co/fonts/inter/Inter-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url('https://s.catch.co/fonts/inter/Inter-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url('https://s.catch.co/fonts/inter/Inter-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'InterDisplay';
  font-weight: 300;
  src: url('https://s.catch.co/fonts/inter/Inter-DisplayLight.woff2') format('woff2');
}

@font-face {
  font-family: 'InterDisplay';
  font-weight: 400;
  src: url('https://s.catch.co/fonts/inter/Inter-Display.woff2') format('woff2');
}

@font-face {
  font-family: 'InterDisplay';
  font-weight: 500;
  src: url('https://s.catch.co/fonts/inter/Inter-DisplayMedium.woff2') format('woff2');
}

@font-face {
  font-family: 'InterDisplay';
  font-weight: 600;
  src: url('https://s.catch.co/fonts/inter/Inter-DisplaySemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'InterDisplay';
  font-weight: 700;
  src: url('https://s.catch.co/fonts/inter/Inter-DisplayBold.woff2') format('woff2');
}

@font-face {
  font-family: 'JetBrains Mono';
  src: url('https://s.catch.co/fonts/jbmono/JetBrainsMono-Bold.eot');
  src: url('https://s.catch.co/fonts/jbmono/JetBrainsMono-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('https://s.catch.co/fonts/jbmono/JetBrainsMono-Bold.woff2') format('woff2'),
    url('https://s.catch.co/fonts/jbmono/JetBrainsMono-Bold.woff') format('woff'),
    url('https://s.catch.co/fonts/jbmono/JetBrainsMono-Bold.ttf') format('truetype'),
    url('https://s.catch.co/fonts/jbmono/JetBrainsMono-Bold.svg#JetBrainsMono-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Caveat';
  src: url('https://s.catch.co/fonts/caveat/Caveat-Regular.woff2') format('woff2'),
    url('https://s.catch.co/fonts/caveat/Caveat-Regular.woff') format('woff'),
    url('https://s.catch.co/fonts/caveat/Caveat-Regular.ttf') format('truetype'),
    url('https://s.catch.co/fonts/caveat/Caveat-Regular.svg#Caveatregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

.push-enter {
  opacity: 0;
  transform: translateX(100%);
  transition: 350ms cubic-bezier(0.23, 1, 0.32, 1);
}

.push-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: 350ms cubic-bezier(0.23, 1, 0.32, 1);
}

.push-exit {
  opacity: 1;
  transform: translateX(0);
  transition: 350ms cubic-bezier(0.23, 1, 0.32, 1);
}

.push-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: 350ms cubic-bezier(0.23, 1, 0.32, 1);
}

.pop-enter {
  opacity: 0;
  transform: translateX(-100%);
  transition: 350ms cubic-bezier(0.23, 1, 0.32, 1);
}

.pop-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: 350ms cubic-bezier(0.23, 1, 0.32, 1);
}

.pop-exit {
  opacity: 1;
  transform: translateX(0);
  transition: 350ms cubic-bezier(0.23, 1, 0.32, 1);
}

.pop-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: 350ms cubic-bezier(0.23, 1, 0.32, 1);
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.treeraw * {
  font-family: 'Jetbrains Mono', monospace !important;
  font-size: 12px;
  font-weight: 500;
  background: none !important;
}

.treeraw {
  ul ul ul {
    border-left: 1px solid #00000011;
  }
  li:last-child {
    padding-bottom: 8px !important;
  }
  ul li ul li ul li {
    margin-left: 24px !important;
  }
}

.tree {
  padding: 36px;
  overflow: scroll;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  ul ul ul {
    border-left: 1px solid #00000011;
  }
  li:last-child {
    padding-bottom: 8px !important;
  }
  ul li ul li ul li {
    margin-left: 36px !important;
  }
}

.theme-dark .tree {
  ul ul ul {
    border-left: 1px solid #ffffff22;
  }
}

.theme-dark .treeraw {
  ul ul ul {
    border-left: 1px solid #ffffff22;
  }
}

.tree * {
}

/* this removes the ugly autofill background */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 5000s ease-in-out 0s;
  transition-property: background-color, color;
  -webkit-text-fill-color: unset !important;
  background: unset !important;
}

#print-footer {
  display: none;
}

@media print {
  @page {
    size: letter;
    size: portrait;
    margin: 0in auto;
  }
  @page rotated {
    size: letter;
    size: portrait;
    margin: 0in auto;
  }
  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  body {
    font-size: 16px;
    margin: 0;
    padding-top: 1in;
    color: #000000;
    background-color: #fff !important;
    width: max(1000px, 100%);
    /* box-sizing: border-box; */
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important; /* Firefox 48 – 96 */
    print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
  }
  .nav,
  [data-print-hide='true'] {
    display: none;
  }
  [data-class='card'] {
    box-shadow: none;
    border: 1px solid #ddd;
  }
  #print-footer {
    position: fixed;
    bottom: 0.25in;
    left: 0;
    right: 0;
    color: black;
    display: block !important;
    z-index: 9999999999;
    font-family: 'Inter';
    font-size: 10px;
    color: gray;
  }
}
